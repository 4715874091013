
// Libs
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import UserListDialog from "@/components/UserListDialog.vue"
import { Tasks } from "@/models/task"
import Vue from "vue"
import { string } from "@/string"
import firebase from "firebase/app"

// Models
import {
  TaskTypes,
  TaskStatus,
  CraftRecord,
  CraftRecordPersistenceTypes,
  InventoryTransactionTypes,
  InventoryInitiatingEvents,
  CraftTypes,
} from "@/models/models"

export default Vue.extend({
  computed: {
    CraftTypes() {
      return CraftTypes
    },
    TaskStatus() {
      return TaskStatus
    },
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    task: {
      type: Tasks,
      required: true,
    },
    craftRecord: {
      type: CraftRecord,
      required: false,
    },
    showCraftPersistence: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    "user-list-dialog": UserListDialog,
  },
  data() {
    return {
      tab: null,
      materialPickup: "pickup",
      taskDetailsConfigs: [],
      updatedTaskDetails: {},
      userModalField: null,
      showUserModal: false,
      loading: false,
      updatedTask: new Tasks(this.task),
      theme: process.env.VUE_APP_THEME,
      inventoryObjects: [],
      transactions: [],
      InventoryTransactionTypes,
    }
  },
  mounted() {
    const siteKeyData = this.$store.state.firetableModule.siteKey
    this.taskDetailsConfigs = Util.getEditableDynamicDetailConfigs(
      siteKeyData,
      null,
      this.updatedTask,
      null
    )

    for (const i in this.taskDetailsConfigs) {
      const config = this.taskDetailsConfigs[i]
      if (config.key in this.updatedTask.taskSpecificDetails) {
        this.updatedTaskDetails[config.key] =
          this.updatedTask.taskSpecificDetails[config.key]
      } else {
        this.updatedTaskDetails[config.key] = config.defaultValue
      }
    }

    this.updatedTaskDetails = { ...this.updatedTask.taskSpecificDetails }

    if (this.craftRecord == null) {
      this.getCraftRecord()
    }
    if (this.showInventoryTransactions()) this.fetchInvObjects()
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async getCraftRecord() {
      this.craftRecord = await Util.getCraftRecord(
        db,
        this.updatedTask.craftRecordID
      )
    },
    getPersistenceTypes() {
      return CraftRecordPersistenceTypes
    },
    setCraftRecordPersistence(option) {
      this.updatedTask.craftRecordPersistence = option
    },
    async fetchInvObjects() {
      try {
        this.loading = true
        const objects = await Util.getInventoryObjectsByCraftType(
          this.$store.state.firetableModule.rootUserData.defaultSiteKey,
          10
        )
        // Set defaults
        const initEvent = InventoryInitiatingEvents.setInitiatingEvent(
          this.task.taskStatus,
          this.task.taskType
        )
        const trxType =
          InventoryInitiatingEvents.getRecommendedTypeFromInitiatingEvent(
            initEvent
          )
        this.inventoryObjects = objects.map((obj) => ({
          ...obj,
          trxQty: 1,
          trxType,
        }))
      } catch (error) {
        Util.errorMessage(error.message)
      } finally {
        this.loading = false
      }
    },
    addTransaction(object) {
      const { id, trxType, trxQty } = object
      // Validations
      if (!trxQty || (trxQty && isNaN(trxQty)))
        return this.$message.warning("Please check the Quantity field")
      const newTrx = {
        // id: Util.randString(10),
        companyId:
          this.$store.state.firetableModule.siteKeyUserPermissionData.companyID,
        inventoryObject: id,
        parentRecordID: this.task.craftRecordID.split("/").pop(),
        taskID: this.task.id,
        uid: this.$store.state.firetableModule.rootUserData.id,
        object,
        type: trxType,
        value: Number(trxQty),
      }
      this.transactions = [newTrx, ...this.transactions]
    },
    removeTransaction(transaction) {
      this.transactions = this.transactions.filter(
        (trx) => trx.id !== transaction.id
      )
    },
    async inventoryBatchOperations() {
      try {
        if (this.transactions.length === 0) return
        const batch = db.batch()
        const siteKey =
          this.$store.state.firetableModule.rootUserData.defaultSiteKey
        const trxCol = `/siteKeys/${siteKey}/inventoryTransactions`
        for (const trx of this.transactions) {
          const {
            companyId,
            inventoryObject,
            parentRecordID,
            taskID,
            type,
            uid,
            value,
          } = trx
          //  Transaction
          const trxRef = db.collection(trxCol).doc()
          const trxData = {
            companyID: companyId,
            inventoryObject,
            parentRecordID,
            taskID,
            type,
            uid,
            value,
            timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
          }
          // Set operation
          batch.set(trxRef, trxData)

          // Quantities
          const objectCol = `/siteKeys/${siteKey}/inventoryObjects`
          const objectRef = db.collection(objectCol).doc(inventoryObject)
          const qtys = {
            timestampLastModified:
              firebase.firestore.FieldValue.serverTimestamp(),
            lastModifiedBy: uid,
            // ...Util.setInventoryTransactionQuantities(trxData),
          }
          // Set operation
          batch.update(objectRef, qtys)
        }

        // Commit the batch
        await batch.commit()
        this.$message.success("Inventory Transactions created")
      } catch (error) {
        Util.errorMessage(error.message)
      }
    },
    showInventoryTransactions() {
      return (
        this.inventoryObjects.length > 0 &&
        this.task.taskStatus === TaskStatus.COMPLETE
      )
    },
    buildSelectOptions(options: { [key: string]: string }) {
      const items = []
      for (const key in options) items.push({ text: options[key], value: key })
      return items
    },
    barrierCommentsRequired() {
      return Util.barrierCommentsRequired({
        currentValues: this.updatedTaskDetails,
        templateValues: this.taskDetailsConfigs,
      })
    },
    checkForRequiredFields() {
      for (const element in this.taskDetailsConfigs) {
        const config = this.taskDetailsConfigs[element]
        // Check for required values
        if (
          config.required &&
          [undefined, "", null].includes(this.updatedTaskDetails[config.key]) &&
          config.onTaskStatus?.includes(this.updatedTask.taskStatus)
        ) {
          return config.title
        }
      }
      return false
    },
    validatePersistenceSelection() {
      if (this.showCraftPersistence === true) {
        if (this.updatedTask.craftRecordPersistence === "prompt") {
          return false
        }
      }
      return true
    },
    async save() {
      try {
        const requiredField = this.checkForRequiredFields()
        if (requiredField) {
          this.loading = false
          return this.$message.warning(
            `Please check the required field: ${requiredField}`
          )
        }
        // Validate Barrier comments (if that field is expected)
        if (
          Util.barrierCommentsRequired({
            currentValues: this.updatedTaskDetails,
            templateValues: this.taskDetailsConfigs,
          })
        ) {
          this.loading = false
          return this.$message.warning(
            "Barrier Comments are required when there is a value for Barrier - Other"
          )
        }

        // Validate Craft Record Persistence option was selected if needed
        if (this.validatePersistenceSelection() === false) {
          this.loading = false
          return this.$message.warning(
            "Please select a craft record persistence option"
          )
        }

        // Save Task Specific Details
        this.taskDetailsConfigs.forEach((config) => {
          // If not required and cleared out, remove it
          if (
            !config.required &&
            (config.type === "number" || config.type === "currency") &&
            [undefined, "", null].includes(this.updatedTaskDetails[config.key])
          ) {
            this.updatedTaskDetails[config.key] = 0
          }

          // Fix numbers parsed as strings
          if (
            (config.type === "number" || config.type === "currency") &&
            config.editable &&
            typeof this.updatedTaskDetails[config.key] === "string"
          ) {
            this.updatedTaskDetails[config.key] = parseInt(
              this.updatedTaskDetails[config.key],
              10
            )
          }

          if (
            (config.type === "number" || config.type === "currency") &&
            config.editable &&
            isNaN(this.updatedTaskDetails[config.key])
          ) {
            this.updatedTaskDetails[config.key] = null
          }
        })

        // Save inventory transactions
        await this.inventoryBatchOperations()

        this.$emit("updateTask", this.updatedTask, this.updatedTaskDetails)
        this.close()
      } catch (error) {
        Util.logOnlyOnDev(error)
        Util.errorMessage(error.message)
      }
    },
    setUserSelection(user) {
      this.updatedTaskDetails[this.userModalField] = user.id
      this.userModalField = null
      this.setShowUserModal(false, null)
    },
    setShowUserModal(value, fieldName) {
      this.showUserModal = value
      this.userModalField = fieldName
    },
    getUserDisplayName(uid) {
      const { siteKeyUsers } = this.$store.state.firetableModule
      return siteKeyUsers?.find((_user) => _user.id === uid)?.displayName ?? ""
    },
    setBtnStyles(): string {
      if (this.theme === "stilt") {
        return "btn-stilt"
      } else {
        return "btn-gradient white--text"
      }
    },
  },
})
