
// Libs
import Vue from "vue"
import firebase from "firebase/app"
import "firebase/functions"
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import { string } from "@/string"
import has from "lodash/has"

// Models
import {
  Asset,
  CraftRecord,
  CraftRecordPersistenceTypes,
  CraftType,
  CraftTypes,
  FlowMapTypes,
  Location,
  NewTaskIconTypes,
  SelectObject,
  SiteKey,
  SiteKeyCompany,
  SiteKeyUsers,
  TaskType,
  TaskTypes,
  TaskStatus,
} from "@/models/models"
import {
  shouldDisplayEstimateDialog,
  Tasks,
  updateTaskFromScheduleChange,
} from "@/models/task"
// Components
import CraftCmp from "@/views/CraftList/List.vue"
import DynamicCraftDetails from "@/components/DynamicCraftDetails.vue"
import DynamicTaskDetails from "@/components/DynamicTaskDetails.vue"
import { displayNextOpportunity } from "@/customizations"
import EstimateModal from "@/components/EstimateModal.vue"
import TaskDate from "@/views/CraftRecord/TaskDate.vue"
import moment from "moment/moment"

interface CreateObject {
  theme: string
  imageData: Array<{}>
  CraftType: CraftType //define class object in data values
  CraftTypes: { [key: string]: any }
  allowedCraftTypes: Array<number>
  validTaskStatusCodes: Array<number>
  TaskType: TaskType //define class object in data values
  FlowMapTypes: FlowMapTypes
  NewTaskIconTypes: NewTaskIconTypes
  TaskStatus: TaskStatus
  taskFrom: number
  taskTypes: Array<TaskTypes>
  locations: Array<Location>
  showEstimateModal: boolean
  receiveTaskNotifications: boolean
  siteKeyCompanies: Array<SelectObject>
  users: Array<SiteKeyUsers>
  assets: Array<SelectObject>
  locationVal: string | null
  latitude: number
  longitude: number
  task: Tasks
  siteKeyData: SiteKey
  craftRecord: CraftRecord
  page: number
  dense: boolean
  loading: boolean
  submitted: boolean
  backButton: boolean
  nextButton: boolean
  craftDetails: any
  taskDetails: any
  existingOrNewAllowedPages: Array<number>
}

Vue.component("craft-list", CraftCmp)
export default Vue.extend({
  async mounted() {
    // this.craftRecord = new CraftRecord()
    await this.initializeData()
  },

  data(): CreateObject {
    return {
      theme: process.env.VUE_APP_THEME,
      imageData: [],
      CraftType: CraftType, //define class object in data values
      CraftTypes,
      receiveTaskNotifications: true,
      allowedCraftTypes: [],
      validTaskStatusCodes: [],
      TaskType: TaskType, //define class object in data values
      FlowMapTypes: FlowMapTypes, //define class object in data values
      NewTaskIconTypes: NewTaskIconTypes, //define class object in data values
      TaskStatus: TaskStatus,
      taskFrom: FlowMapTypes.CREATE_CRAFT_RECORD,
      taskTypes: [],
      locations: [],
      users: [],
      siteKeyCompanies: [],
      assets: [],
      showEstimateModal: false,
      locationVal: "",
      latitude: 0,
      longitude: 0,
      task: new Tasks({} as Tasks),
      siteKeyData: {} as SiteKey,
      craftRecord: null,
      page: 0,
      dense: false,
      loading: false,
      submitted: false,
      backButton: true,
      nextButton: true,
      craftDetails: null,
      taskDetails: null,
      existingOrNewAllowedPages: [NewTaskIconTypes.CRAFT_RECORD],
    }
  },
  computed: {
    defaultSiteKey(): string | null {
      if (!this.$store.state.firetableModule.rootUserData) return null
      return this.$store.state.firetableModule.rootUserData.defaultSiteKey
    },
    globalCraftType(): string | number {
      return this.$store.state.firetableModule.globalCraftType
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    siteKeyUserLocations(): any | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
  },
  watch: {
    defaultSiteKey(newValue: string | null) {
      if (newValue) this.initializeData()
    },
    globalCraftType(newValue: string | null) {
      if (newValue) this.initializeData()
    },
    siteKeyUserPermissionData(newValue: any | null) {
      if (newValue) this.initializeData()
    },
    siteKeyUserLocations(newValue: any | null) {
      if (newValue) this.initializeData()
    },
  },
  components: {
    "dynamic-craft-details": DynamicCraftDetails,
    "dynamic-task-details": DynamicTaskDetails,
    "task-date": TaskDate,
    EstimateModal,
  },
  methods: {
    async initializeData() {
      const { rootUserData } = this.$store.state.firetableModule

      if (!rootUserData || !this.siteKeyUserPermissionData) return null
      const { defaultSiteKey } = rootUserData

      // get all siteKey locations data
      this.locations = await Util.getSiteKeyLocations(db, defaultSiteKey)
      this.locations = this.locations.sort((a, b) =>
        a.title.localeCompare(b.title)
      )

      //Get all data related to SiteKey
      this.siteKeyData = await Util.getSiteKey(db, defaultSiteKey)

      // Get allowed craft types and task status codes
      this.allowedCraftTypes = this.siteKeyData.validCraftTypes
      this.validTaskStatusCodes = this.siteKeyData.validTaskStatusCodes

      if (this.$route.params.craftType && this.$route.params.craftID) {
        // New task from existing craft record
        this.taskFrom = FlowMapTypes.NEW_TASK_FROM_CRAFT_RECORD
        this.task.craftType = parseInt(this.$route.params.craftType, 10)
        await this.getValidTaskTypes()
        this.page = NewTaskIconTypes.TASK_TYPE
        const craftIDpath = `siteKeys/${defaultSiteKey}/parentRecords/${this.$route.params.craftID}`
        const _cr = await Util.getCraftRecord(db, craftIDpath)
        if (_cr) {
          this.craftRecord = _cr
          this.task.locationID = this.craftRecord.locationID
          this.task.craftRecordID = this.craftRecord.refPath
            ? this.craftRecord.refPath
            : ""
          this.task.thumbnailURL = this.craftRecord.thumbnailURL
        }
        this.backButton = false
      } else {
        // Did not come from existing craft record
        this.page = NewTaskIconTypes.CRAFT_TYPE
        this.taskFrom = FlowMapTypes.CREATE_CRAFT_RECORD
        this.task.craftType =
          this.globalCraftType !== "all"
            ? parseInt(this.globalCraftType, 10)
            : CraftType.values()[0]

        if (this.locations.length > 0) {
          const defaultLoc = this.locations[0]
          this.task.locationID = defaultLoc.id ? defaultLoc.id : ""
          this.latitude = defaultLoc.latitude
          this.longitude = defaultLoc.longitude
        }

        this.craftRecord = new CraftRecord({
          id: null,
          refPath: null,
          multiCraftRecordID: null,
          assetID: null,
          closedTasks: [],
          closedTaskTypes: [],
          authorizedCompanies: [],
          craftDetails: {},
          craftType: this.task.craftType,
          createdBy: rootUserData.id,
          description: null,
          latitude: this.latitude,
          locationID: this.task.locationID,
          longitude: this.longitude,
          numClosedTasks: 0,
          numOpenTasks: 0,
          open: true,
          openTasks: [],
          openTaskTypes: [],
          thumbnailURL: null,
          timestampRecordClosed: null,
          timestampRecordCreated: null,
          timestampLastModified: null,
          lastModifiedBy: null,
          title: null,
        })
      }

      //initial task record details set
      this.task.createdBy = rootUserData.id
      this.task.crewCount = 0
      this.task.durations = {}
      this.task.holdDurations = {}
      this.task.nextOpportunity = false
      this.task.taskSpecificDetails = {}
      this.task.craftRecordPersistence = CraftRecordPersistenceTypes.KEEP //Default
      this.task.notifyCompanyOnCreation = true
      this.task.urgent = false
      this.task.nextOpportunity = false
      this.task.timestampScheduled = firebase.firestore.Timestamp.fromDate(
        moment().toDate()
      )
      this.task = await updateTaskFromScheduleChange(
        this.task,
        this.scheduleVal,
        true,
        this.$store.state.firetableModule.siteKey,
        this.$store.state.firetableModule.siteKeyUserPermissionData
      )

      // Only comapnies that allow that craft type
      await this.allowedCompanies()

      // get all site key assets list and assign to select dropdown
      const assets = await Util.getSiteKeyAssets(db, defaultSiteKey)
      this.assets = assets.map((record: Asset) => {
        return {
          text: record.title,
          value: record.id ? record.id : "",
        }
      })

      // Get user's data
      if (defaultSiteKey) await this.getUsersData()
    },

    async getUsersData() {
      try {
        const {
          rootUserData: { defaultSiteKey },
        } = this.$store.state.firetableModule
        const getVisibleCompaniesAndUsers = firebase
          .functions()
          .httpsCallable("getVisibleCompaniesAndUsers")
        const {
          data: { data, code },
        } = await getVisibleCompaniesAndUsers({
          siteKey: defaultSiteKey,
        })
        if (code !== 200) {
          throw new Error("Visible companies and users not available")
        }
        let userIds = []
        for (const companyId in data)
          if (data[companyId]) userIds = [...userIds, ...data[companyId]]

        // Make it an unique list
        userIds = [...new Set(userIds)]

        this.users = await Promise.all(
          userIds.map((id) => Util.getSiteKeyUserDetail(db, defaultSiteKey, id))
        )
      } catch (error) {
        Util.errorMessage(error.message)
      }
    },
    setShowEstimateModal(value: boolean) {
      this.showEstimateModal = value
    },

    craftDetailsUpdated(updatedData) {
      this.craftDetails = updatedData
    },

    taskSpecificDetailsUpdated(updatedData) {
      this.taskDetails = updatedData
    },

    taskNotificationOptIn(val) {
      this.receiveTaskNotifications = val
    },

    changeTaskStatus(newStatus: number) {
      this.task.taskStatus = newStatus
    },

    updateTask(updatedTask: Tasks) {
      this.task = updatedTask
    },

    async allowedCompanies() {
      // Get all site key companies list to assign to select dropdown
      const {
        rootUserData: { defaultSiteKey },
      } = this.$store.state.firetableModule
      const companies = await Util.getSiteKeyCompanies({
        db,
        defaultSiteKey,
        userPermissions: this.siteKeyUserPermissionData,
      })

      // Map and Filter the allowed companies that can accept this craft record
      this.siteKeyCompanies = companies
        .filter((record: SiteKeyCompany) =>
          record.craftTypes.includes(parseInt(this.craftRecord.craftType, 10))
        )
        .map((record: SiteKeyCompany) => ({
          text: record.name,
          value: record.id ? record.id : "",
        }))
      // auto-assign if just one company
      if (this.siteKeyCompanies.length === 1)
        this.task.assignedCompanyID = this.siteKeyCompanies[0].value
    },

    formatDate: (data) => Util.formatDate(data),

    craftTypeChange() {
      this.task.taskType = null
      this.craftRecord.craftType = this.task.craftType
      // Re-apply filters
      this.allowedCompanies()
    },

    // user select craft type against task type load function
    async getValidTaskTypes() {
      this.taskTypes = TaskType.getValidTaskTypesForSite(
        this.task.craftType,
        this.siteKeyData
      )

      // If create-task called from a craft record detail, no installation
      if (
        this.$route.params.craftID &&
        this.taskTypes.includes(TaskTypes.INSTALLATION)
      ) {
        this.taskTypes = this.taskTypes.filter(
          (i) => i !== TaskTypes.INSTALLATION
        )
      }

      // this.task.taskType = this.taskTypes[0]

      if (this.taskTypes.length === 1) {
        this.task.taskType = this.taskTypes[0]
        await this.taskTypeChange()
        this.nextStep(NewTaskIconTypes.TASK_TYPE)
      } else if (!this.$route.params.craftType) {
        this.nextStep(NewTaskIconTypes.CRAFT_TYPE)
      }
      this.task = await updateTaskFromScheduleChange(
        this.task,
        this.scheduleVal,
        true,
        this.$store.state.firetableModule.siteKey,
        this.$store.state.firetableModule.siteKeyUserPermissionData
      )
    },

    // upload image part
    previewImage(event: any) {
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files.length > 0) {
        for (const file of input.files) {
          // create a new FileReader to read this image and convert to base64 format
          const reader = new FileReader()
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e: any) => {
            const img: any = new Image()
            img.src = e.target.result
            img.onload = () => {
              const fullUrl = this.getFullImageUrl(img)
              const reducedUrl = this.getReducedImageUrl(img)
              const thumbUrl = this.getThumbImageUrl(img)
              const temp: {} = {
                fileName: file.name,
                fullUrl: fullUrl,
                reducedUrl: reducedUrl,
                thumbUrl: thumbUrl,
              }
              this.imageData.push(temp)
            }
          }
          reader.readAsDataURL(file)
        }
      }
    },

    // reduce the image quality using canvas
    // get the full image url function
    getFullImageUrl(img: HTMLImageElement) {
      const elem = document.createElement("canvas")
      elem.width = img.width
      elem.height = img.height
      const ctx: any = elem.getContext("2d")
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, img.width, img.height)
      const data = ctx.canvas.toDataURL("image/jpeg", 1)
      elem.remove()
      return data
    },

    // reduce the image quality using canvas
    // get the reduced image url function
    getReducedImageUrl(img: HTMLImageElement) {
      const elem = document.createElement("canvas")
      elem.width = img.width
      elem.height = img.height
      const ctx: any = elem.getContext("2d")
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, img.width, img.height)
      const data = ctx.canvas.toDataURL("image/jpeg", 0.8)
      elem.remove()
      return data
    },

    // reduce the image quality using canvapage: numbers
    // get the thumb image url function
    getThumbImageUrl(img: HTMLImageElement) {
      const elem = document.createElement("canvas")
      elem.width = 300
      elem.height = 300
      const ctx: any = elem.getContext("2d")
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, 300, 300)
      const data = ctx.canvas.toDataURL("image/jpeg", 1)
      elem.remove()
      return data
    },

    //uploaded image revert function.
    removeImage(index: number) {
      this.imageData.splice(index, 1)
    },

    updateLocation(location: { [key: string]: any }) {
      this.latitude = location.lat
      this.longitude = location.lng
    },

    //validate title form
    titleForm() {
      if (!this.$refs.titleForm.validate()) return
      this.submitted = true
      if (this.craftRecord.title) {
        this.submitted = false
        this.nextStep(NewTaskIconTypes.CRAFT_RECORD_INFO)
      }
    },

    //validate company form
    companyForm() {
      // if (!this.$refs.companyForm.validate()) return
      this.submitted = true
      if (this.task.assignedCompanyID) {
        this.submitted = false
        if (
          shouldDisplayEstimateDialog(
            this.task,
            this.siteKeyData,
            this.siteKeyUserPermissionData
          ) === true
        ) {
          this.setShowEstimateModal(true)
        } else {
          this.submitTask()
        }
      } else {
        return this.$message.warning(`Please select an assigned company`)
      }
    },

    // go to nextpage
    nextStep(TaskIconTypes: number) {
      if (TaskIconTypes === NewTaskIconTypes.TASK_TYPE && !this.task.taskType) {
        this.$store.commit(
          "firetableModule/setError",
          "Please select a task type"
        )
        return this.page
      }
      if (
        this.task.craftRecordID == null &&
        TaskIconTypes === NewTaskIconTypes.CRAFT_RECORD
      ) {
        this.$store.commit(
          "firetableModule/setError",
          "Please select a craft record from the list"
        )
        return this.page
      }

      const flowMapType =
        FlowMapTypes.flowMapArrangement()[this.taskFrom].indexOf(TaskIconTypes)
      if (
        flowMapType !== -1 &&
        flowMapType + 1 <
          FlowMapTypes.flowMapArrangement()[this.taskFrom].length
      ) {
        this.page =
          FlowMapTypes.flowMapArrangement()[this.taskFrom][flowMapType + 1]
        this.nextButton = true
        this.backButton = true
      } else {
        this.nextButton = false
        this.backButton = false
      }

      const calendarOptions = []
      calendarOptions.push("Urgent")
      if (
        this.$store.state.firetableModule.siteKey.validTaskStatusCodes.includes(
          TaskStatus.AWAITING_SCHEDULE
        )
      ) {
        calendarOptions.push("Awaiting Schedule")
      }
      if (
        displayNextOpportunity(
          this.$store.state.firetableModule.siteKey,
          this.task.craftType,
          this.task.taskType
        ) === true
      ) {
        calendarOptions.push("Next Opportunity")
      }

      this.calendarOptions = calendarOptions
      return this.page
    },

    // go to previous page
    previousStep(TaskIconTypes: number) {
      const flowMapType =
        FlowMapTypes.flowMapArrangement()[this.taskFrom].indexOf(TaskIconTypes)
      if (flowMapType !== -1 && flowMapType - 1 >= 0) {
        this.page =
          FlowMapTypes.flowMapArrangement()[this.taskFrom][flowMapType - 1]
        this.nextButton = true
        this.backButton = true
      } else {
        this.backButton = false
        this.nextButton = false
      }
      return this.page
    },

    // if location change means we can update latitude and longitude value
    locationChange() {
      const { latitude, longitude } = this.locations.find(
        (record: Location) => this.task.locationID === record.id
      )
      this.latitude = latitude
      this.longitude = longitude
    },

    async taskTypeChange() {
      if (this.task.taskType === TaskTypes.INSTALLATION) {
        this.taskFrom = FlowMapTypes.CREATE_CRAFT_RECORD
      } else if (!this.$route.params.craftType) {
        this.taskFrom = FlowMapTypes.NEW_TASK_FROM_HOME
      } else if (this.task.craftType === CraftTypes.FILTER_CLOTHS) {
        this.taskFrom = FlowMapTypes.NEW_TASK_FROM_HOME
      }
      this.task = await updateTaskFromScheduleChange(
        this.task,
        this.scheduleVal,
        true,
        this.$store.state.firetableModule.siteKey,
        this.$store.state.firetableModule.siteKeyUserPermissionData
      )
    },

    newCraftRecord() {
      // Go back, changes the flow type and go next
      this.previousStep(NewTaskIconTypes.CRAFT_RECORD)
      this.taskFrom = FlowMapTypes.CREATE_CRAFT_RECORD
      this.nextStep(NewTaskIconTypes.TASK_TYPE)
    },

    showExistingOrNewCR() {
      // Here we check if the questions id allowed for the current flow step
      const currentStep =
        FlowMapTypes.flowMapArrangement()[this.taskFrom][this.page]
      return this.existingOrNewAllowedPages.includes(currentStep)
    },

    selectCraftRecord(craftRecord: CraftRecord) {
      this.craftRecord = craftRecord
      this.task.craftRecordID = craftRecord.refPath ? craftRecord.refPath : ""
      this.task.locationID = craftRecord.locationID
        ? craftRecord.locationID
        : ""
      this.task.thumbnailURL = craftRecord.thumbnailURL
        ? craftRecord.thumbnailURL
        : null
      this.nextStep(NewTaskIconTypes.CRAFT_RECORD)
    },

    // task and craft record store function
    async submitTask() {
      this.showEstimateModal = false

      // if user data not available means we return null function
      if (!this.$store.state.firetableModule.rootUserData) return null

      // set loading
      this.loading = true
      let saveTask

      const { defaultSiteKey, displayName, id } =
        this.$store.state.firetableModule.rootUserData

      this.craftRecord.authorizedCompanies = [
        this.siteKeyUserPermissionData.companyID,
      ]

      // Timestamps and modified fields
      this.task.timestampCreated = firebase.firestore.Timestamp.now()
      this.task.timestampLastModified =
        firebase.firestore.FieldValue.serverTimestamp()
      this.task.lastModifiedBy = `${id}`
      this.craftRecord.timestampRecordCreated =
        firebase.firestore.Timestamp.now()
      this.craftRecord.timestampLastModified =
        firebase.firestore.FieldValue.serverTimestamp()
      this.craftRecord.lastModifiedBy = `${id}`

      if (this.task.taskStatus === TaskStatus.AWAITING) {
        this.task.timestampAwaitingStart = firebase.firestore.Timestamp.now()
      }

      // task title set
      const taskTypeName = TaskType.getTaskTypeString(this.task.taskType)
      this.task.title = `${taskTypeName} - ${this.craftRecord.title}`

      // Get Craft record Persistence
      this.task.craftRecordPersistence = Util.getRecordPersistence(
        this.craftRecord.craftType,
        this.task.taskType
      )

      let parentRecordId

      // if tasktype installation means we save craft record and tasks details
      if (!this.craftRecord.id) {
        // map task location into craft record location
        this.craftRecord.locationID = this.task.locationID
        this.craftRecord.longitude = this.longitude
        this.craftRecord.latitude = this.latitude
        const craftRecord: {} = new CraftRecord(this.craftRecord).toMap()
        const craftPath = `siteKeys/${defaultSiteKey}/parentRecords`

        // save craft record details.
        const saveCraftRecord: any = await Util.saveCraftRecord(
          db,
          defaultSiteKey,
          craftPath,
          craftRecord,
          this.imageData
        )
        if (saveCraftRecord) {
          // Save craftDetails by calling the cloud function (if applies)
          parentRecordId = saveCraftRecord[0].split("/").pop()
          await this.saveCraftRecordDetails({
            defaultSiteKey,
            parentRecordId,
          })
          // Save task record details.
          this.task.craftRecordID = saveCraftRecord[0]
          this.task.thumbnailURL = saveCraftRecord[1]
          const taskRecord: {} = new Tasks(this.task).toMap()
          const taskDocPath = `siteKeys/${defaultSiteKey}/tasks`
          saveTask = await Util.saveTask(db, taskDocPath, taskRecord)
        } else {
          Util.errorMessage("Error trying to save this task.")
        }
      } else {
        const taskRecord: any = this.task.toMap()
        const taskDocPath = `siteKeys/${defaultSiteKey}/tasks`
        parentRecordId = taskRecord.craftRecordID.split("/").pop()
        // save task record details.
        saveTask = await Util.saveTask(db, taskDocPath, taskRecord)
      }

      if (saveTask) {
        //Add notification subscription (if opt'd in)
        if (this.receiveTaskNotifications) {
          const taskSubscriber = {
            key: `${id}`,
            displayName,
          }
          await Util.subscribeToTask(
            db,
            defaultSiteKey,
            taskSubscriber,
            saveTask.id
          )
        }
        await this.saveTaskDetails(saveTask)
        this.$store.commit("firetableModule/setSuccess", string.taskAdded)
        await this.$router.push(
          `/craft-record/${parentRecordId}/${saveTask.id}`
        )
      } else {
        this.$store.commit("firetableModule/setError", string.generalError)
      }
      this.loading = false
    },

    async saveTaskDetails(saveTask): Promise<void> {
      if (this.taskDetails == null || this.taskDetails.length == 0) {
        return
      }
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const craftDetailName = CraftType.getCraftTypeRecordString(
            this.task.craftType
          )
          const taskDetailName = TaskTypes.getTaskTypeName(this.task.taskType)
          // Save task specific details by calling the cloud function (if applies)
          if (
            !has(
              this.siteKeyData,
              `customizations.taskSpecificDetails.${craftDetailName}.${taskDetailName}`
            )
          )
            return resolve()
          const saveTaskDetails = firebase
            .functions()
            .httpsCallable("updateTaskSpecificDetails")
          const data = {
            ...this.taskDetails,
            refPath: saveTask.path,
          }
          await saveTaskDetails(data)
          resolve()
        } catch (error) {
          this.$store.commit("firetableModule/setError", string.generalError)
          reject(error)
        }
      })
    },

    async saveCraftRecordDetails({
      defaultSiteKey,
      parentRecordId,
    }): Promise<void> {
      if (this.craftDetails) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const craftDetailName = CraftType.getCraftTypeRecordString(
              this.craftRecord.craftType
            )
            if (
              !has(
                this.siteKeyData,
                `customizations.craftDetails.${craftDetailName}`
              )
            )
              return resolve()
            const saveCraftDetails = firebase
              .functions()
              .httpsCallable("updateCraftDetails")
            await saveCraftDetails({
              ...this.craftDetails,
              refPath: `siteKeys/${defaultSiteKey}/parentRecords/${parentRecordId}`,
            })
            resolve()
          } catch (error) {
            this.$store.commit("firetableModule/setError", string.generalError)
            reject(error)
          }
        })
      }
    },

    // filterDetailsByTaskStatus() {
    //   const { craftType, taskType, taskStatus } = this.task
    //   const {
    //     customizations: { taskSpecificDetails = {} } = {},
    //   } = this.siteKeyData
    //   // Check if this craftType - taskType combination has specific details
    //   const craftDetailName = CraftType.getCraftTypeRecordString(craftType)
    //   const taskDetailName = TaskTypes.getTaskTypeName(taskType)
    //   const taskDetailsAvailable = has(
    //     taskSpecificDetails,
    //     `${craftDetailName}.${taskDetailName}`
    //   )
    //   if (!taskDetailsAvailable) return {}
    //
    //   // Get detail template in customizations
    //   const details = {}
    //   const detailTemplate =
    //     taskSpecificDetails[craftDetailName][taskDetailName]
    //   for (const key in detailTemplate) {
    //     if (!detailTemplate[key].onTaskStatus || !detailTemplate[key].editable)
    //       continue
    //     if (detailTemplate[key].onTaskStatus.includes(taskStatus))
    //       details[key] = detailTemplate[key]
    //   }
    //   // Original customizations object structure
    //   return {
    //     taskSpecificDetails: {
    //       [craftDetailName]: { [taskDetailName]: details },
    //     },
    //   }
    // },
  },
})
