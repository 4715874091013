
import Vue from "vue"

import "firebase/auth"
import "firebase/firestore"

interface CreateObject {
  theme: string
  counts: { [key: string]: any }
}
export default Vue.extend({
  data(): CreateObject {
    return {
      theme: process.env.VUE_APP_THEME,
      counts: {
        allTask: 0,
        inProgress: 0,
        urgent: 0,
        scheduled: 0,
        inspection: 0,
        backlog: 0,
      },
    }
  },
  computed: {
    siteClassification(): string | null {
      const siteKey = this.$store.state.firetableModule.siteKey
      return siteKey?.customizations?.siteClassification
    },
  },
  methods: {
    randomLevel(): string {
      const choices = ["info", "success", "warning", "error-dark", "error"]
      return choices[Math.floor(Math.random() * choices.length)]
    },
    randomMsg(): string {
      const choices = [
        "Hello, World!",
        "Danger, everything is going wrong",
        "Something went wrong. Please try again or contact support",
        "Your document has been saved!",
      ]
      return choices[Math.floor(Math.random() * choices.length)]
    },
  },
})
