
import Vue from "vue"
import { Util } from "./helpers"
import { db } from "./firebase-init"
import {
  RootUser,
  SiteKeyUsers,
  CraftType,
  CraftTypes,
  SiteKeyUserPermission,
  TaskStatus,
  SiteKey,
} from "./models/models"
import { useScrollLock } from "@/composables/scroll-lock"
import firebase from "firebase/app"

import { mapGetters, mapActions } from "vuex"
import {
  assignedToEnabled,
  complianceEnabled,
  customersEnabled,
  inventoryEnabled,
  invoicesEnabled,
  routingEnabled,
  schedulingDispatchEnabled,
  priceBookEnabled,
  kpisEnabled,
  customersSiteClassification,
  feedbackEnabled,
  estimatesEnabled,
  kpisV2Enabled,
} from "@/customizations"
import {is} from "@amcharts/amcharts4/core";

interface CreateObject {
  theme: string
  headerLogo: string
  helpLink: string
  drawer: boolean | null
  disabled: boolean
  absolute: boolean
  openOnHover: boolean
  value: boolean
  closeOnClick: boolean
  closeOnContentClick: boolean
  offsetX: boolean
  offsetY: boolean
  CraftType: CraftType
  globalCraftType: any
  globalCraftDropdownList: [{ [key: string]: any }]
  craftDropdownList: any[]
  craftList: CraftTypes[]
  showcraftList: boolean
  showTaskList: boolean
  showInventoryMenu: boolean
  CraftTypes: any
  stiltlogo: boolean
  taskList: any[]
  snackbar: boolean
  snackBarColor: string
  snackbarText: string
  provideExampleEnabled: boolean
  releaseVersion?: string
  ffEnableCompliance: boolean
  ffEnableCustomers: boolean
  ffEnableInvoices: boolean
}

const scrollLock = useScrollLock()

export default Vue.extend({
  name: "App",
  components: {},
  props: {
    source: String,
  },
  data(): CreateObject {
    return {
      theme: process.env.VUE_APP_THEME,
      headerLogo: process.env.VUE_APP_LOGO_WHITE,
      helpLink: process.env.VUE_APP_HELP_LINK,
      drawer: null,
      disabled: false,
      absolute: false,
      openOnHover: false,
      value: false,
      closeOnClick: true,
      closeOnContentClick: true,
      offsetX: false,
      offsetY: true,
      CraftType: CraftType,
      globalCraftType: "all",
      globalCraftDropdownList: [{}],
      craftDropdownList: [{}],
      craftList: CraftType.values(),
      showcraftList: false,
      showTaskList: false,
      showInventoryMenu: false,
      CraftTypes: CraftTypes,
      stiltlogo: false,
      taskList: [],
      snackbar: false,
      snackBarColor: "",
      snackbarText: "",
      provideExampleEnabled:
        process.env.VUE_APP_PROVIDE_INJECT_EXAMPLE === "true",
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      ffEnableCompliance: process.env.VUE_APP_ENABLE_COMPLIANCE === "true",
      ffEnableCustomers: process.env.VUE_APP_ENABLE_CUSTOMERS === "true",
      ffEnableInvoices: process.env.VUE_APP_ENABLE_INVOICES === "true",
    }
  },

  computed: {
    ...mapGetters("rootUserPrivateDocModule", ["getRootUserSiteKeys"]),
    ...mapGetters("userCompanyModule", [
      "getUsersSiteKeyCompanyDoc",
      "getChecklistsEnabled",
    ]),
    ...mapGetters("aggregatesModule", ["getUserDisplayNames"]),
    ...mapGetters("messagesModule", ["getMessages"]),
    //get current user details
    firebaseUser(): firebase.User | null {
      return this.$store.state.firetableModule.currentUser
    },
    rootUser(): RootUser | null {
      return this.$store.state.firetableModule.rootUserData
    },
    rootUserSiteKeys(): Record<string, boolean> | null {
      return this.getRootUserSiteKeys
    },
    siteKeyUser(): SiteKeyUsers | null {
      return this.$store.state.firetableModule.siteKeyUserData
    },
    siteKeyData(): SiteKey | null {
      return this.$store.state.firetableModule.siteKey
    },
    siteKeyUserPermissions(): SiteKeyUserPermission | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    defaultSiteKey(): string | null {
      if (!this.$store.state.firetableModule.rootUserData) return null
      return this.$store.state.firetableModule.rootUserData.defaultSiteKey
    },
    // snackbar error message show and hide method
    error(): string | null {
      const errorMsg: string | null = this.$store.state.firetableModule.error
      return errorMsg
    },
    // snackbar success message show and hide method
    success(): string | null {
      const successMsg: string | null =
        this.$store.state.firetableModule.success
      return successMsg
    },
    loading(): boolean {
      return this.$store.state.firetableModule.isLoading
    },
    globalCraftTypeData(): string | number {
      return this.globalCraftType
    },
  },
  watch: {
    firebaseUser(newUser: firebase.User | null) {
      if (newUser != null) {
        this.$store.dispatch("firetableModule/listenRootUserData", newUser.uid)
        this.$store.dispatch(
          "rootUserPrivateDocModule/listenRootUserPrivateDoc",
          { uid: newUser.uid }
        )
      } else {
        // User has logged out, need to remove the rootUserDataListener
        this.$store.commit("firetableModule/unsubscribeRootUserDataListener")
        return
      }
    },

    rootUser(rootUser: RootUser | null) {
      if (rootUser != null) {
        if (this.rootUserSiteKeys[rootUser.defaultSiteKey] === true) {
          // Getting the following data requires there to at least be a rootUser first
          this.$store.dispatch("firetableModule/listenSiteKeyUserData")
          this.$store.dispatch("firetableModule/listenSiteKeyUserPermissions")
          this.$store.dispatch("firetableModule/listenSiteKeyUserLocations")
          this.$store.dispatch("firetableModule/listenSiteKeyUsers")
          this.$store.dispatch("firetableModule/listenSiteKeyLocations")
          this.$store.dispatch("firetableModule/listenSiteKey")
        }
      }
    },

    siteKeyUserPermissions(
      siteKeyUserPermissions: SiteKeyUserPermission | null
    ) {
      // Getting the SiteKey Companies requires the user permissions
      if (siteKeyUserPermissions != null) {
        this.$store.dispatch("firetableModule/listenSiteKeyCompanies")
        this.$store.dispatch("aggregatesModule/listenUserDisplayNames", {
          siteKey: this.defaultSiteKey,
          siteKeyUserPermissions: siteKeyUserPermissions,
        })
      }
    },

    rootUserSiteKeys() {
      // If the rootUser's siteKeys map changes (because an admin approved them), then we need to
      //  update the listener on their rootUser document to trigger the SiteKey related data if needed
      this.$store.commit("firetableModule/unsubscribeRootUserDataListener")

      if (this.firebaseUser?.uid != null) {
        this.$store.dispatch(
          "firetableModule/listenRootUserData",
          this.firebaseUser.uid
        )
      }
    },

    defaultSiteKey(newValue: string | null) {
      // If the default siteKey changes, we just need to reset the global craft Type
      if (newValue != null) {
        this.setGlobalCraftType()
        this.updateTaskList()
      }
    },

    siteKeyData(siteKey: SiteKey | null) {
      this.updateTaskList(siteKey)
    },

    globalCraftTypeData(newValue) {
      if (!newValue) return
      const routeName: string = this.$route.name || ""
      if (routeName === "task-list") {
        // Unsubscribe first
        this.$store.commit("firetableModule/unsubscribeTasklistListener")
        this.$store.commit("firetableModule/removeTaskList")
        // Re-Subscribe
        this.$store.dispatch(
          "firetableModule/listenTaskList",
          this.$route.params.taskParam
        )
      } else if (routeName === "craft-list") {
        if (this.globalCraftType !== "all") {
          this.$router.push({
            name: "craft-list",
            params: { craftType: parseInt(this.globalCraftType, 10) },
          })
        } else {
          this.$store.dispatch("firetableModule/listenCraftRecordsList")
        }
      } else if (["task-detail", "view-craftrecord"].includes(routeName)) {
        this.$router.push("/home")
      }
    },

    // When error value append means snackbar show
    error(newValue) {
      if (newValue) {
        this.snackbar = true
        this.snackbarText = newValue
        this.snackBarColor = "error-dark"
        this.$store.dispatch("firetableModule/clearMessage")
      }
    },

    // When success value append means snackbar show
    success(newValue) {
      if (newValue) {
        this.snackbar = true
        this.snackbarText = newValue
        this.snackBarColor = "success"
        this.$store.dispatch("firetableModule/clearMessage")
      }
    },
  },
  mounted() {
    scrollLock.init()
    this.$store.dispatch("firetableModule/listenOnAuthChanged")
    // Set selected Global Craft Type
    const currentCraftType = localStorage.getItem("globalCraftType")
    this.$store.commit(
      "firetableModule/setGlobalCraftType",
      currentCraftType || "all"
    )
    this.updateTaskList(this.$store.state.firetableModule.siteKey)
  },
  methods: {
    ...mapActions("messagesModule", ["removeMsg"]),
    checkUserPermission(permission: string): boolean {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
        ?.permissions[permission]
    },
    assignedToEnabled() {
      return assignedToEnabled(this.siteKeyData)
    },
    async setGlobalCraftType() {
      if (this.rootUserSiteKeys === null) return
      if (this.rootUser?.defaultSiteKey === null) return
      if (this.rootUserSiteKeys[this.rootUser.defaultSiteKey] != true) return
      const siteKeyData = await Util.getSiteKey(db, this.defaultSiteKey)
      const globalCraftTypes: any = siteKeyData.validCraftTypes.map(
        (value) => ({
          text: CraftType.getCraftTypeString(value),
          value,
        })
      )
      this.craftDropdownList = [
        { text: "All Work Types", value: "all" },
        ...globalCraftTypes,
      ]
      const saved = localStorage.getItem("globalCraftType")
      this.globalCraftType =
        saved && saved !== "all" ? parseInt(saved, 10) : "all"
      this.globalCraftTypeChange(this.globalCraftType)
    },
    globalCraftTypeChange(craftType) {
      this.globalCraftType = craftType
      localStorage.setItem("globalCraftType", craftType)
      this.$store.commit("firetableModule/setGlobalCraftType", craftType)
      this.craftList = CraftType.values()
    },
    fbSignOut() {
      localStorage.setItem("globalCraftType", "all")
      this.$store.dispatch("firetableModule/firebaseSignOut")
    },
    disMissSnackbar() {
      this.$store.dispatch("firetableModule/clearMessage")
    },
    updateTaskList(siteKey: SiteKey | null) {
      this.taskList = []
      this.taskList.push({
        name: "All Open Tasks",
        icon: "mdi-format-list-bulleted",
        type: "All",
      })
      if (
        siteKey?.validTaskStatusCodes.includes(TaskStatus.AWAITING_ESTIMATE) ||
        siteKey?.validTaskStatusCodes.includes(TaskStatus.AWAITING_APPROVAL)
      ) {
        this.taskList.push({
          name: "Estimate/Approval",
          icon: "mdi-timelapse",
          type: "Estimate Approval",
        })
      }
      if (
        siteKey?.validTaskStatusCodes.includes(TaskStatus.AWAITING_ESTIMATE) ||
        siteKey?.validTaskStatusCodes.includes(TaskStatus.AWAITING_APPROVAL) ||
        siteKey?.validTaskStatusCodes.includes(TaskStatus.AWAITING_REVIEW) ||
        siteKey?.validTaskStatusCodes.includes(TaskStatus.AWAITING_PARTS)
      ) {
        this.taskList.push({
          name: "Awaiting Review",
          icon: "mdi-timelapse",
          type: "Awaiting Review",
        })
      }
      this.taskList.push(
        { name: "Scheduled", icon: "mdi-calendar-today", type: "Scheduled" },
        { name: "In Progress", icon: "mdi-clock-outline", type: "In Progress" },
        { name: "Urgent", icon: "mdi-bell-alert", type: "Urgent" },
        { name: "Inspections", icon: "mdi-check-circle", type: "Inspection" },
        { name: "Backlog", icon: "mdi-timelapse", type: "Backlog" },
        { name: "Completed", icon: "mdi-check-all", type: "Completed" }
      )
    },
    isInvoicesEnabled() {
      return invoicesEnabled(this.siteKeyData)
    },
    isEstimatesEnabled() {
      return estimatesEnabled(this.siteKeyData)
    },
    isComplianceEnabled() {
      return complianceEnabled(this.siteKeyData)
    },
    isSchedulingEnabled() {
      return schedulingDispatchEnabled(this.siteKeyData)
    },
    isKpisEnabled() {
      return kpisEnabled(this.siteKeyData)
    },
    isKpisV2Enabled() {
      const isEnabled = kpisV2Enabled(this.siteKeyData) && this.siteKeyUserPermissions?.permissions?.isSiteAdmin === true
      console.log(isEnabled)
      return isEnabled
    },
    isInventoryEnabled() {
      return inventoryEnabled(this.siteKeyData)
    },
    isFeedbackEnabled() {
      return feedbackEnabled(this.siteKeyData)
    },
    isCustomersEnabled() {
      return customersEnabled(this.siteKeyData)
    },
    isPriceBookEnabled() {
      return priceBookEnabled(this.siteKeyData)
    },
    isRoutingEnabled() {
      return routingEnabled(this.siteKeyData)
    },
    isSiteClassificationCustomers() {
      return customersSiteClassification(this.siteKeyData)
    },
  },
  beforeCreate() {
    this.$store.dispatch("initStoreFromLocalStorage")
  },
})
