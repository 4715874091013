
// Models
import { Util } from "@/helpers"
import "firebase/functions"
import "firebase/firestore"
import UserListDialog from "@/components/UserListDialog.vue"
import { isEqual } from "lodash"
import { CraftRecord } from "@/models/models"
import Vue from "vue"
import omitBy from "lodash/omitBy"

export default Vue.extend({
  props: {
    craftRecord: {
      type: CraftRecord,
      required: false,
    },
    craftRecordCreation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    "user-list-dialog": UserListDialog,
  },
  data() {
    return {
      craftDetailsConfigs: [],
      originalCraftDetails: {},
      updatedCraftDetails: {},
      userModalField: null,
      showUserModal: false,
    }
  },
  mounted() {
    if (this.craftRecord != null) {
      this.initializeData()
    }
  },
  watch: {
    craftRecord(newValue: CraftRecord | null) {
      if (newValue) {
        this.initializeData()
      }
    },
  },
  methods: {
    initializeData() {
      const siteKeyData = this.$store.state.firetableModule.siteKey

      // Get editable configs
      this.craftDetailsConfigs = Util.getEditableDynamicDetailConfigs(
        siteKeyData,
        this.craftRecord,
        null,
        null
      )

      // If this is a craftRecord creation, filter out the ones that need to be hidden
      if (this.craftRecordCreation === true) {
        this.craftDetailsConfigs = this.craftDetailsConfigs.filter((a) => {
          return !a.hideOnCraftRecordCreation
        })
      }

      for (const i in this.craftDetailsConfigs) {
        const config = this.craftDetailsConfigs[i]
        if (config.key in this.craftRecord.craftDetails) {
          this.updatedCraftDetails[config.key] =
            this.craftRecord.craftDetails[config.key]
        } else {
          this.updatedCraftDetails[config.key] = config.defaultValue
        }
      }
      this.originalCraftDetails = { ...this.updatedCraftDetails }
    },
    updateCraftDetails() {
      const original = { ...this.originalCraftDetails }
      const diffData = omitBy(this.updatedCraftDetails, function (val, key) {
        return isEqual(val, original[key])
      })
      this.$emit("change", diffData)
    },
    checkValues(values) {
      // TODO: Dry this up, this type of logic exists elsewhere
      for (const i in this.craftDetailsConfigs) {
        const config = this.craftDetailsConfigs[i]
        if (
          (config.type === "number" || config.type === "currency") &&
          config.editable &&
          typeof values[config.key] === "string"
        ) {
          values[i] = parseInt(values[i], 10)
        }

        if (
          (config.type === "number" || config.type === "currency") &&
          config.editable &&
          isNaN(values[config.key])
        ) {
          values[config.key] = null
        }
      }
      return values
    },
    setUserSelection(user) {
      this.updatedCraftDetails[this.userModalField] = user.id
      this.$emit("change", this.checkValues(this.updatedCraftDetails))
      this.userModalField = null
      this.setShowUserModal(false, null)
    },
    setShowUserModal(value, fieldName) {
      this.showUserModal = value
      this.userModalField = fieldName
    },
    getUserDisplayName(uid) {
      const { siteKeyUsers } = this.$store.state.firetableModule
      return siteKeyUsers?.find((_user) => _user.id === uid)?.displayName ?? ""
    },
  },
})
