
// Libs
import { Drawer } from "ant-design-vue"

// Components
import DynamicTaskDetails from "@/components/DynamicTaskDetails.vue"
import Vue from "vue"
import { Util } from "@/helpers"

export default Vue.extend({
  props: ["task", "companies", "loading", "siteKeyData"],
  components: {
    "a-drawer": Drawer,
    "dynamic-task-details": DynamicTaskDetails,
  },
  data() {
    return {
      valid: false,
      drawerWidth: window.innerWidth < 960 ? "90%" : "50%",
      updatedTaskDetails: {},
      taskDetailsConfigs: [],
    }
  },
  mounted() {
    const siteKeyData = this.$store.state.firetableModule.siteKey
    const taskData = { ...this.task }
    this.taskDetailsConfigs = Util.getEditableDynamicDetailConfigs(
      siteKeyData,
      null,
      taskData,
      null
    )

    for (const i in this.taskDetailsConfigs) {
      const config = this.taskDetailsConfigs[i]
      if (config.key in taskData.taskSpecificDetails) {
        this.updatedTaskDetails[config.key] =
          taskData.taskSpecificDetails[config.key]
      } else {
        this.updatedTaskDetails[config.key] = config.defaultValue
      }
    }

    // this.updatedTaskDetails = { ...taskData.taskSpecificDetails }
  },
  methods: {
    close() {
      this.$emit("close")
    },

    updateDynamicValues(values) {
      this.updatedTaskDetails = values
    },

    saveForm() {
      const { assignedCompanyID, description, refPath } = this.task
      const updatedTaskData = {
        assignedCompanyID,
        description,
        refPath,
      }

      // Validate Barrier comments (if that field is expected)
      if (
        Util.barrierCommentsRequired({
          currentValues: this.updatedTaskDetails,
          templateValues: this.taskDetailsConfigs,
        })
      ) {
        this.loading = false
        return this.$message.warning(
          "Barrier Comments are required when there is a value for Barrier - Other"
        )
      }

      Object.keys(this.updatedTaskDetails).forEach((key) => {
        const config = this.taskDetailsConfigs.find((e) => e.key === key)

        // Fix numbers parsed as strings
        if (
          (config.type === "number" || config.type === "currency") &&
          config.editable &&
          typeof this.updatedTaskDetails[key] === "string"
        ) {
          this.updatedTaskDetails[key] = parseInt(
            this.updatedTaskDetails[key],
            10
          )
        }

        if (
          (config.type === "number" || config.type === "currency") &&
          config.editable &&
          isNaN(this.updatedTaskDetails[key])
        ) {
          this.updatedTaskDetails[key] = null
        }
      })
      this.$emit("save", updatedTaskData, this.updatedTaskDetails)
    },
  },
})
